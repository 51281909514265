import { log } from '../utils';
import { configContext } from './config';
import { isDev } from './qdot';

class Indexer {
  #endpoint;
  #enabled = null;
  constructor(endpoint) {
    this.#endpoint = endpoint;
  }

  get isEnabled() {
    if (this.#enabled === null) {
      this.#enabled = configContext
        .fetchValue(configContext.configKeys.searchEnabled)
        .asBoolean();
    }

    return this.#enabled;
  }
  async indexQueue(queue) {
    if (!this.#enabled) return;
    
    const doc = {
      payload: [
        {
          id: queue.queue_address,
          content: queue.name,
          docType: 'queue',
        },
      ],
    };

    try {
      const resp = await fetch(this.#endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(doc),
      });

      const result = await resp.json();
      log({
        component: 'Indexer',
        msg: 'indexing results',
        results: result,
      });
      return true;
    } catch (error) {
      log({ component: 'Indexer', msg: 'Error indexing queue', error: error });
      return false;
    }
  }

  async indexTrack(track) {
    if (!this.#enabled) return;

    const doc = {
      payload: [
        {
          id: `${track.queue_address}/${track.track_address}`,
          content: track.title,
          docType: 'track',
        },
      ],
    };

    try {
      const resp = await fetch(this.#endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(doc),
      });

      const result = await resp.json();
      log({
        component: 'Indexer',
        msg: 'indexing results',
        results: result,
      });
      return true;
    } catch (error) {
      log({ component: 'Indexer', msg: 'Error indexing queue', error: error });
      return false;
    }
  }
}

class Searcher {
  #endpoint;
  constructor(endpoint) {
    this.#endpoint = endpoint;
  }

  async search(query) {
    try {
      const response = await fetch(`${this.#endpoint}/search?query=${query}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const { results } = await response.json();
      log({ component: 'Searcher', msg: 'search results', results: results });
      return results;
    } catch (error) {
      log({ component: 'Searcher', msg: 'Error sending query', error: error });
      return [];
    }
  }
}

const endpoint = isDev()
  ? 'http://localhost:8080/api/v1'
  : 'https://search-dot-musicq-7f247.uc.r.appspot.com/api/v1';

export const indexer = new Indexer(endpoint);

export const searcher = new Searcher(endpoint);
