import { remoteConfig } from '..';
import { getValue } from 'firebase/remote-config';
import { log } from '../utils';

class ConfigContext {
  #initailized = false;
  #config;
  #configKeys = {
    bottomNavEnable: 'bottomNavEnabled',
    newUiFlowEnabled: 'newUiFlowEnabled',
    searchEnabled: 'searchEnabled',
    loggingEnabled: 'loggingEnabled',
    geofenceQueueEnabled: 'geofenceQueueEnabled',
  };

  #init() {
    this.#config = remoteConfig;
    this.#config.defaultConfig = {
      bottomNavEnable: false,
      newUiFlowEnabled: true,
      searchEnabled: true,
      loggingEnabled: false,
      geofenceQueueEnabled: true,
    };
    this.#initailized = true;
  }

  fetchValue(key) {
    if (!this.#initailized) this.#init();
    const configValue = getValue(this.#config, key);
    return configValue;
  }

  get configKeys() {
    return { ...this.#configKeys };
  }
}

export const configContext = new ConfigContext();
