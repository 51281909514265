import { log } from '../utils';

export class GeoPositionWatcher {
  #watchGeoPosition = undefined;
  #currentDistance = undefined;
  #watchID = undefined;

  #internalNavigator = undefined;
  #onPositionChangeListener = undefined;
  #listenerID = undefined;

  constructor(listenerID, watchPos, navigator, onPositionChangeListener) {
    this.#listenerID = listenerID;
    this.#watchGeoPosition = watchPos;
    this.#watchID = navigator.geolocation.watchPosition(
      this.#positionWatcher.bind(this),
      error => {},
      {
        enableHighAccuracy: true,
        maximumAge: 8.64e7,
        timeout: 5000,
      }
    );

    this.#internalNavigator = navigator;
    this.#onPositionChangeListener = onPositionChangeListener;
  }

  get currentDistance() {
    return this.#currentDistance;
  }

  #positionWatcher(geoPosition) {
    const { coords } = geoPosition;
    this.#triggerGeoPositionChange(coords);
  }

  stopWatch() {
    this.#internalNavigator.geolocation.clearWatch(this.#watchID);
  }

  #triggerGeoPositionChange(coords) {
    const distance = this.#computeDistance(this.#watchGeoPosition, coords);
    this.#currentDistance = distance;
    if (this.#onPositionChangeListener !== undefined) {
      log({
        component: 'GeoPositionWatcher',
        msg: 'sending position change event',
        distance: distance,
      });
      this.#onPositionChangeListener(distance, this.#listenerID);
    }
  }

  #computeDistance(refCoords, coords) {
    // The math module contains a function named toRadians which converts from degrees to radians.
    const lon1 = (refCoords.longitude * Math.PI) / 180;
    const lon2 = (coords.longitude * Math.PI) / 180;
    const lat1 = (refCoords.latitude * Math.PI) / 180;
    const lat2 = (coords.latitude * Math.PI) / 180;

    // Haversine formula
    const dlon = lon2 - lon1;
    const dlat = lat2 - lat1;
    const a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);

    const c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 6371 for KM and 3956 for MI
    const earthRadius = 3956;

    // calculate the result
    const d = c * earthRadius;
    return d;
  }
}
