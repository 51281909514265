import { Tabs, TabList, Tab, Text, Box } from '@chakra-ui/react';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import LayersIcon from '@mui/icons-material/Layers';
export default function BottomNav(props) {
  return (
    <Tabs isFitted bg={'yellow.400'} width={'100%'} height={'60px'} margin={0}>
      <TabList>
        <Tab>
          <Box>
            <HomeIcon />
            <Text>Home</Text>
          </Box>
        </Tab>
        <Tab>
          <Box>
            <SearchIcon />
            <Text>Search</Text>
          </Box>
        </Tab>
        <Tab>
          <Box>
            <LayersIcon />
            <Text>Your Queues</Text>
          </Box>
        </Tab>
      </TabList>
    </Tabs>
  );
}
