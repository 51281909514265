/** @jsxRuntime classic */
/** @jsx jsx */
import {
  Box,
  Input,
  Button,
  Container,
  InputGroup,
  InputLeftElement,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import { debounce } from '@mui/material';
import { useCallback, useState } from 'react';
import { firebaseFacade } from '../api/firebase';
import Schemas, { Constraints } from '../api/schemas';
import { log } from '../utils';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import { css, jsx } from '@emotion/react';
import { NavigationEvent, UiStates } from './stream.component';
import { searcher } from '../api/indexing';
import { useWallet } from '@solana/wallet-adapter-react';
import { Progress } from '@chakra-ui/react';
export function Search(props) {
  const { onPushView, onPopView } = props;
  const { PRIVATE } = Constraints.queueType;
  const { publicKey } = useWallet();

  const [queues, setQueues] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState(null);

  const loadTracks = useCallback(async tracks => {
    const loadedTracks = [];
    for (var i = 0; i < tracks.length; i++) {
      const { id } = tracks[i];
      const idFragments = id.split('/');
      const track = await firebaseFacade.loadDoc(
        `${Schemas.db_node_schema.queues}/${idFragments[0]}/${Schemas.db_node_schema.tracks}/${idFragments[1]}`
      );
      if (track !== null) {
        loadedTracks.push(track);
      }
    }
    setTracks(loadedTracks);
  }, []);

  const loadQueues = useCallback(
    async queues => {
      const loadedQueues = [];
      for (var i = 0; i < queues.length; i++) {
        const { id } = queues[i];
        const queue = await firebaseFacade.loadDoc(
          `${Schemas.db_node_schema.queues}/${id}`
        );
        if (queue !== null) {
          if (queue.type !== PRIVATE) {
            loadedQueues.push(queue);
          } else {
            if (publicKey && queue.owner_wallet_address === publicKey.toBase58())
              loadedQueues.push(queue);
          }
        }
      }
      setQueues(loadedQueues);
      setLoading(false);
    },
    [publicKey]
  );

  const handleSearchResult = useCallback(result => {
    const queues = [];
    const tracks = [];
    for (var i = 0; i < result.length; i++) {
      const doc = result[i];
      if (doc.docType === 'track') {
        tracks.push(doc);
      } else {
        queues.push(doc);
      }
    }

    loadTracks(tracks);
    loadQueues(queues);
  }, []);

  const handleSearch = useCallback(
    debounce(function ({ target: { value: query } }) {
      setLoading(true);
      setQuery(query);
      searcher
        .search(query)
        .then(handleSearchResult)
        .catch(error =>
          log({
            component: 'Search',
            msg: 'Search endpoint return error',
            error: error,
          })
        );
    }, 500),
    []
  );

  const handleBack = useCallback(() => {
    log({ component: 'Search', msg: 'back pressed!' });
    onPopView();
  });

  const handleSelect = useCallback(queue => {
    log({
      component: 'Search',
      msg: 'queue selected from search result!',
      queue: queue,
    });
    onPushView(new NavigationEvent(UiStates.six, { queue: queue }));
  });

  return (
    <Container>
      <Button
        onClick={handleBack}
        color={'yellow.400'}
        backgroundColor={'transparent'}
      >
        <ArrowBackIos />
      </Button>
      <InputGroup color={'yellow.400'} marginTop={'8px'} marginBottom={'8px'}>
        <InputLeftElement pointerEvents={'none'} children={<SearchIcon />} />
        <Input
          autoFocus
          placeholder="Search for queues"
          onChange={handleSearch}
        />
      </InputGroup>
      {loading && <Progress colorScheme={'yellow'} size="xs" isIndeterminate />}
      <Box
        css={css`
          display: grid;
          grid-template-columns: auto auto auto;
          gap: 8px;
        `}
      >
        {queues.map(function (queue) {
          return (
            <Box margin={'8px'} onClick={() => handleSelect(queue)}>
              <Image
                src={`${
                  queue.art_uri ||
                  process.env.PUBLIC_URL + '/logo-transparent.png'
                }`}
                bg={'gray.900'}
                shadow={'lg'}
                borderRadius={'2xl'}
              />
              <Text className="text-white" fontWeight={'thin'} noOfLines={1}>
                {queue.name}
              </Text>
            </Box>
          );
        })}
      </Box>
      {!loading && queues.length === 0 && query && (
        <VStack width={'100%'}>
          <Text fontSize="10em" color={'yellow.400'}>
            404
          </Text>
          <Text fontSize="4xl" color={'white'}>
            Nothing to see here
          </Text>
        </VStack>
      )}
    </Container>
  );
}
